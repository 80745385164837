<template>
  <el-tiptap
    :extensions="extensions"
    :content="content"
    v-model="content"
    :placeholder="placeholder"
    class="EditorStyles"
    :charCounterCount="false"
    v-bind=$attrs
  />
</template>

<script>
import {
  // all extensions
  Doc,
  Text,
  Paragraph,
  Heading,
  Bold,
  Italic,
  Strike,
  Underline,
  Link,
  Image,
  Blockquote,
  ListItem,
  BulletList, // use with ListItem
  OrderedList, // use with ListItem
  TodoItem,
  TodoList, // use with TodoItem
  TextAlign,
  Indent,
  HorizontalRule,
  HardBreak,
  History,
  // LineHeight,
  // Iframe,
  // CodeBlock,
  // TrailingNode,
  // Table, // use with TableHeader, TableCell, TableRow
  // TableHeader,
  // TableCell,
  // TableRow,
  // FormatClear,
  TextColor,
  // TextHighlight,
  // Preview,
  // Print,
  //Fullscreen,
  CodeView,
  // SelectAll,
} from "element-tiptap";
import codemirror from "codemirror";
import "codemirror/lib/codemirror.css"; // import base style
import "codemirror/mode/xml/xml.js"; // language
import "codemirror/addon/selection/active-line.js"; // require active-line.js
import "codemirror/addon/edit/closetag.js"; // autoCloseTags
export default {
  name: "Editor",
props:['productDescription','placeholder'],
  data: () => ({
    extensions: [
      new Doc(),
      new Text(),
      new Paragraph(),
      new Heading({ level: 5 }),
      new Bold({ bubble: true }),
      new Underline({ bubble: true }),
      new Italic({ bubble: true }),
      new Strike({ bubble: true }),
      new Link({ bubble: true }),
     new Image(),
      new Blockquote(),
      new TextAlign(),
      new ListItem(),
      new BulletList({ bubble: true }),
      new OrderedList({ bubble: true }),
      new TodoItem(),
      new TodoList(),
      new Indent(),
      new HardBreak(),
      new HorizontalRule({ bubble: true }),
      new TextColor(),
    //   new CodeView(),
      new CodeView({
        codemirror,
        codemirrorOptions: {
          styleActiveLine: true,
          autoCloseTags: true,
          lineNumbers: true,
  lineWrapping: true,
  tabSize: 2,
  tabMode: 'indent',
  mode: 'text/html',
        },
      }),
      new History(),
    ],
    content:``
  }),

  mounted(){
    this.content = this.productDescription
  },
  watch:{
    content(newVal){
        this.$emit('updateDescription',newVal)
    }
  }
};
</script>

<style scoped>
.EditorStyles{
    max-height: 400px;
}
</style>
